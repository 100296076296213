import React from "react";

function formatDateRange(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const startMonth = start.toLocaleString("default", { month: "long" });
  const startDay = start.getUTCDate();
  const startYear = start.getUTCFullYear();

  const endMonth = end.toLocaleString("default", { month: "long" });
  const endDay = end.getUTCDate();
  const endYear = end.getUTCFullYear();

  let dateRange = "";

  if (startMonth === endMonth && startYear === endYear) {
    dateRange = `${startMonth} ${startDay} - ${endDay}, ${startYear}`;
  } else if (startYear === endYear) {
    dateRange = `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`;
  } else {
    dateRange = `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
  }

  return dateRange;
}

export default function PayrollCard({ onClickItem, index, payroll, progress }) {
  const date = formatDateRange(payroll?.startDate, payroll?.endDate);

  if (progress) return null;

  return (
    <div
      className=" rounded p-4 position-relative m-0"
      style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)" }}
    >
      <div className="row p-0 m-0">
        <p
          className="m-0"
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: "#434343",
          }}
        >
          {payroll?.name}
        </p>
      </div>
      <div className="row p-0 m-0">
        <p
          className="m-0 opacity-50 "
          style={{ color: "#434343", fontSize: ".875ren" }}
        >
          {date}
        </p>
      </div>
      {payroll?.status !== "Paid" ? (
        <button
          className="btn btn-link position-absolute"
          style={{
            top: ".5rem",
            right: ".5rem",
            color: "#DAB44E",
            fontSize: ".875rem",
          }}
          onClick={() => onClickItem(payroll?.id)}
        >
          <i class="bi bi-pencil me-2" />
          Edit
        </button>
      ) : null}
    </div>
  );
}
