import React from "react";
import InputFactory from "../../../components/InputFactory";
import { formatCurrency } from "../../../formatCurrency";

export default function AdditionalPayments({
  object,
  onIncreasesAndDeductionsChange,
  cashAdvanceInfo,
  startDate,
  endDate,
}) {
  // const totalCashAdvance = cashAdvanceInfo
  //   .filter((ca) => ca.createdAt >= startDate && ca.createdAt <= endDate)
  //   .reduce(
  //     (acc, curr) =>
  //       acc + (parseFloat(curr.amounts.replace(/,/g, ""), 10) || 0),
  //     0
  //   );
  const totalCashAdvance = cashAdvanceInfo
    .filter(
      (ca) =>
        ca.createdAt >= startDate &&
        ca.createdAt <= endDate &&
        ca.statuses === "Approved"
    )
    .reduce(
      (acc, curr) =>
        acc + (parseFloat(curr.amounts.replace(/,/g, ""), 10) || 0),
      0
    );

  console.log("CASHADVANCsE", totalCashAdvance);

  return (
    <>
      <p
        className="fw-semibold mt-5"
        style={{ fontSize: "1.25rem", color: "#434343" }}
      >
        Additional Payments
      </p>
      <div
        className="col rounded m-0 p-3 ps-5 pe-5"
        style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)" }}
      >
        <div className="row mt-3 mb-4 align-items-center ">
          <div className="col-4">Allowance</div>
          <div className="col-7">
            <InputFactory
              type="Peso"
              object={object}
              onChange={onIncreasesAndDeductionsChange}
              field="allowance"
            />
          </div>
        </div>
        <div className="row mt-3 mb-4 align-items-center ">
          <div className="col-4">CA Request</div>
          <div className="col-auto">
            <InputFactory
              type="Boolean"
              object={object}
              onChange={onIncreasesAndDeductionsChange}
              field="caRequest"
            />
          </div>
          <div className="col-auto">{formatCurrency(totalCashAdvance)}</div>
        </div>
      </div>
    </>
  );
}
