import BaseListPresenter from "../../../base/BaseListPresenter";
import { aggregateUseCase } from "../../../usecases/object";

class EditPayrollPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.findObjectUseCase = findObjectUseCase;
    this.overallTotal = 0;
  }

  componentDidMount() {
    this.init();
    return this.getObjects();
  }

  async getObjects() {
    this.reset();
    await this.getPayrollInfo();
    // await this.countObjects();
    // await this.findObjects();
    // this.attachDefaults();
    await this.getHolidays();
    await this.getEmployeePayrollInfo();
  }

  getRoleId(name, roles) {
    return roles.find((role) => role.id === name).id;
  }

  formatDateForQuery(dateInput) {
    const date = new Date(dateInput);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  async getHolidays() {
    try {
      const holidays = await this.findObjectUseCase.execute("holidays", {
        keys: ["date", "type"],
        include: ["type"],
      });
      this.view.setState({ holidays });
    } catch (error) {
      console.log(error);
    }
    this.progress = false;
  }

  onClickItem(payrollId, userId, startDate, endDate, payrollType) {
    this.view.navigateTo("/forms/payroll-info-edit/" + payrollId, {
      payrollId,
      userId,
      startDate,
      endDate,
      payrollType,
    });
  }

  onClickEdit(payrollId) {
    this.view.navigateTo("/forms/payroll-info/" + payrollId);
  }

  onClickBack() {
    this.view.navigateBack();
  }

  onPayrollChange(value, field) {
    let obj = {};
    obj[field] = value;
    this.view.setState((prev) => ({
      ...prev,
      payroll: { ...prev.payroll, ...obj },
    }));
  }

  exportPayrollInfoToText(users, payrollDate) {
    let txtData = "";

    users.forEach((obj) => {
      if (obj.bankNumber) {
        txtData += `${obj.bankNumber}\t\t${obj.total}\n`;
      }
    });

    const blob = new Blob([txtData], { type: "text/plain;charset=utf-8" });

    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);

    a.download = `${this.formatDate(payrollDate)}1.txt`;

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  }

  async onStatusClick(status, payrollId, payrollDate) {
    const payrollObject = this.view.state.payroll;

    try {
      if (status === "Draft") {
        await this.upsertUseCase.execute("payroll", {
          status: "Approved",
          id: payrollId,
        });
        this.view.showToast("Successfully Approved!");
        this.getObjects();
      } else if (status === "Approved") {
        this.view
          .showAccountsSelectionModal()
          .then(async () => {
            if (
              !payrollObject.bank ||
              !payrollObject.chartOfAccounts ||
              !payrollObject.project_name
            ) {
              this.view.showToast(
                "Please choose a bank and chart of accounts.",
                "error"
              );
              return;
            }

            const totalAmount = this.view.state.objects.reduce(
              (acc, current) => acc + parseFloat(current.total),
              0
            );

            // GET BANK INFO
            const [bank] = await this.findObjectUseCase.execute(
              "gmd_accounts",
              {
                where: { name: this.view.state.payroll.bank.name },
              }
            );

            // UPDATE BANK BALANCE
            await this.upsertUseCase.execute("gmd_accounts", {
              balance: bank.balance - totalAmount,
              id: bank.id,
            });

            // CREATE TRANSACTION RECORD
            const [allBank] = await aggregateUseCase().execute("gmd_accounts", [
              {
                $group: {
                  _id: null,
                  total: { $sum: "$balance" },
                },
              },
            ]);

            console.log("Payroll", this.view.state.payroll);

            await this.upsertUseCase.execute("transaction", {
              types: { type: "Money Out" },
              note: this.view.state.payroll.name,
              amounts: totalAmount.toString(),
              account: this.view.state.payroll.bank,
              project_name: this.view.state.payroll.project_name,
              chart_of_accounts: this.view.state.payroll.chartOfAccounts,
              account_balance: allBank.total - totalAmount,
            });

            // CREATE EXPENSES RECORD
            await this.upsertUseCase.execute("expense", {
              project_name: this.view.state.payroll.project_name,
              amount: totalAmount,
              accounts: this.view.state.payroll.bank,
              items: [],
            });

            await this.upsertUseCase.execute("payroll", {
              status: "Paid",
              id: payrollId,
              bank: payrollObject.bank,
              chartOfAccounts: payrollObject.chartOfAccounts,
            });

            this.view.showToast("Successfully Paid!");

            this.getObjects();
          })
          .catch((error) => {
            this.view.submissionError(error);
            this.view.showError(error);
          });
      } else {
        this.exportPayrollInfoToText(this.view.state.objects, payrollDate);
      }
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }

  async getPayrollInfo() {
    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute("payroll", {
        where: { id: this.view.getParams().id },
      });
      this.view.setPayrollObject(objects[0]);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
  }

  async getRoles() {
    try {
      const roles = await this.findObjectUseCase.execute("roles");
      return roles;
    } catch (error) {
      console.log(error);
    }
    this.progress = false;
  }

  countRegularHolidays() {
    const holidays = this.view.state.holidays;
    const { startDate, endDate } = this.view.state.payroll;

    const filteredHolidays = holidays.filter((holiday) =>
      holiday.type.name.includes("Regular Holiday")
    );

    let regularHolidayCount = 0;

    const getMonthDay = (date) => {
      const d = new Date(date);
      return {
        month: d.getMonth() + 1,
        day: d.getDate(),
        year: d.getFullYear() + 1,
      };
    };

    const start = getMonthDay(startDate);
    const end = getMonthDay(endDate);

    filteredHolidays.forEach((holiday) => {
      const holidayDate = getMonthDay(holiday.date);

      if (
        (holidayDate.month > start.month ||
          (holidayDate.month === start.month &&
            holidayDate.day >= start.day &&
            holidayDate.year === start.year)) &&
        (holidayDate.month < end.month ||
          (holidayDate.month === end.month &&
            holidayDate.day <= end.day &&
            holidayDate.year === start.year))
      ) {
        regularHolidayCount++;
      }
    });

    // holidays.forEach((holiday) => {
    //   const holidayDate = new Date(holiday.date);
    //   const holidayMonth = holidayDate.getMonth() + 1;
    //   const holidayDay = holidayDate.getDate();

    //   const startMonth = new Date(startDate).getMonth() + 1;
    //   const startDay = new Date(startDate).getDate();

    //   const endMonth = new Date(endDate).getMonth() + 1;
    //   const endDay = new Date(endDate).getDate();

    //   if (
    //     holidayMonth === startMonth &&
    //     holidayDay >= startDay &&
    //     holidayMonth === endMonth &&
    //     holidayDay <= endDay
    //   ) {
    //     regularHolidayCount++;
    //   }
    // });

    return regularHolidayCount;
  }

  // async getEmployeePayrollInfo() {
  //   try {
  //     this.showProgress();
  //     const employeePayrolls = await this.findObjectUseCase.execute(
  //       "employee_payroll_info",
  //       {
  //         keys: ["daysWorked", "subtotal", "deductions", "total", "userId"],
  //         where: { payrollId: this.view.getParams().id },
  //       }
  //     );
  //     console.log("employeePayrolls", employeePayrolls);
  //     this.overallTotal = 0;
  //     const newObjects = await Promise.all(
  //       employeePayrolls.map(async (employeePayroll) => {
  //         const [user] = await this.findObjectUseCase.execute("users", {
  //           where: { id: employeePayroll.userId },
  //         });

  //         console.log("payroll user", user);

  //         const regularHolidayPay =
  //           user?.employeeType === "On call"
  //             ? 0
  //             : this.countRegularHolidays() * user?.dailyRate || 0;

  //         const total =
  //           employeePayroll.total ||
  //           user?.monthlyRate / 2 ||
  //           0 + regularHolidayPay;
  //         this.overallTotal += total;

  //         console.log("REG", regularHolidayPay);
  //         console.log("Total:", employeePayroll.total);

  //         console.log("REG", regularHolidayPay);

  //         return {
  //           ...employeePayroll,
  //           id: user?.id,
  //           fullName: user?.fullName,
  //           position: user?.position,
  //           subtotal:
  //             employeePayroll.subtotal ||
  //             user?.monthlyRate / 2 ||
  //             0 + regularHolidayPay,
  //           total:
  //             employeePayroll.total ||
  //             user?.monthlyRate / 2 ||
  //             0 + regularHolidayPay,
  //         };
  //       })
  //     );

  //     newObjects.sort((a, b) => a.fullName?.localeCompare(b.fullName));
  //     console.log("presenter object", newObjects);

  //     this.view.setState({ objects: newObjects });
  //     this.hideProgress();
  //   } catch (error) {
  //     this.hideProgress();
  //     this.view.showError(error);
  //   }
  // }
  async getEmployeePayrollInfo() {
    try {
      this.showProgress();

      const excludeUserIds = [
        "5a3598e5-43fd-4b82-82ca-dfcf18bf762a",
        "22541fdc-1192-47e3-b678-9e2213e0f184",
        "a82bc15c-7fdf-402d-b4d3-959ba425cef0",
        "0d22bbcc-ba54-4d6d-ba33-3ed487f4ab93",
      ];

      const employeePayrolls = await this.findObjectUseCase.execute(
        "employee_payroll_info",
        {
          keys: ["daysWorked", "subtotal", "deductions", "total", "userId"],
          where: { payrollId: this.view.getParams().id },
        }
      );

      // Filter out the userIds that need to be hidden
      const filteredPayrolls = employeePayrolls.filter(
        (employeePayroll) => !excludeUserIds.includes(employeePayroll.userId)
      );

      console.log("filteredPayrolls", filteredPayrolls);

      this.overallTotal = 0;
      const newObjects = await Promise.all(
        filteredPayrolls.map(async (employeePayroll) => {
          const [user] = await this.findObjectUseCase.execute("users", {
            where: { id: employeePayroll.userId },
          });

          console.log("payroll user", user);
          console.log("employeePayroll", employeePayroll);

          const regularHolidayPay =
            user?.employeeType === "On call"
              ? 0
              : this.countRegularHolidays() * user?.dailyRate || 0;

          console.log("regularHolidayPay", regularHolidayPay);

          const total =
            employeePayroll.total ||
            // user?.monthlyRate / 2 ||
            0 + regularHolidayPay;
          this.overallTotal += total;

          console.log("TOTS", total);

          console.log("REG", regularHolidayPay);
          console.log("Total:", employeePayroll.total);

          return {
            ...employeePayroll,
            id: user?.id,
            fullName: user?.fullName,
            position: user?.position,
            status: user?.status,
            subtotal:
              employeePayroll.subtotal ||
              user?.monthlyRate / 2 ||
              0 + regularHolidayPay,
            total:
              employeePayroll.total ||
              user?.monthlyRate / 2 ||
              0 + regularHolidayPay,
          };
        })
      );

      newObjects.sort((a, b) => a.fullName?.localeCompare(b.fullName));

      const activeUsers = newObjects.filter(
        (employeePayroll) => employeePayroll.status === "Active"
      );

      // console.log("presenter object", newObjects);

      // this.view.setState({ objects: newObjects });
      console.log("presenter object", activeUsers);

      this.view.setState({ objects: activeUsers });
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
  }

  formatDate(dateString) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${month}/${day}/${year}`;
  }
}

export default EditPayrollPresenter;
