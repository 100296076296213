import PayrollComputations from "./PayrollComputations";

class EditIndividualPayrollPresenter extends PayrollComputations {
  constructor(
    view,
    getObjectUseCase,
    upsertUseCase,
    restUseCase,
    findObjectUseCase
  ) {
    super(view, getObjectUseCase, upsertUseCase, restUseCase);
    this.view = view;
    this.getObjectUseCase = getObjectUseCase;
    this.upsertUseCase = upsertUseCase;
    this.restUseCase = restUseCase;
    this.findObjectUseCase = findObjectUseCase;
  }

  // OVERRIDES
  componentDidMount() {
    this.init();
    this.getNeededObjects();
  }

  async getNeededObjects() {
    await this.getObject();
    await this.getPayrollUserInfo();
    await this.getHolidays();
    await this.getLeaveRequestInfo();
    this.getEmployeePayrollInfo();
    this.getCashAdvance();
  }

  getDefaultWorkHours() {
    return 80;
  }

  formatDateForQuery(dateInput) {
    const date = new Date(dateInput);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  async getPayrollUserInfo() {
    const { userId } = this.view.getArgument();
    try {
      const user = await this.findObjectUseCase.execute("users", {
        where: { id: userId },
      });
      this.view.setState({ userInfo: user[0] });
    } catch (error) {
      console.log(error);
    }
    this.progress = false;
  }

  async getHolidays() {
    try {
      const holidays = await this.findObjectUseCase.execute("holidays", {
        keys: ["date", "type"],
        include: ["type"],
      });
      console.log("holsss", holidays);
      this.view.setState({ holidays });
    } catch (error) {
      console.log(error);
    }
    this.progress = false;
  }

  async getCashAdvance() {
    const { userId } = this.view.getArgument();
    try {
      const cashAdvance = await this.findObjectUseCase.execute("cash_advance", {
        where: { name: { id: userId }, statuses: "Approved" },
      });

      this.view.setState({ cashAdvanceInfo: cashAdvance });
    } catch (error) {
      console.log(error);
    }
    this.progress = false;
  }

  async getLeaveRequestInfo() {
    const { userId } = this.view.getArgument();
    console.log("USERID ", userId);
    try {
      const leave = await this.findObjectUseCase.execute("leave_request", {
        where: {
          name: { id: userId },
          statuses: "Paid",
        },
        include: ["type"],
      });

      console.log("LEAVE", leave);

      this.view.setState({ leaveRequestInfo: leave });
    } catch (error) {
      console.log(error);
    }
    this.progress = false;
  }

  async getEmployeePayrollInfo() {
    const { userId, payrollId } = this.view.getArgument();
    const { employeeType } = this.view.state.userInfo;

    try {
      const [employeePayroll] = await this.findObjectUseCase.execute(
        "employee_payroll_info",
        { where: { userId, payrollId } }
      );

      // INSERT CASH ADVANCE TO THE OBJECT
      const payrollType = this.view.state.object.type;

      let updatedObject = {};

      if (payrollType === "Weekly") {
        const regularHolidayCount = this.countRegularHolidays();

        if (regularHolidayCount > 0) {
          this.view.setState((prev) => ({
            ...prev,
            regularHolidayPay:
              employeeType === "On call"
                ? 0
                : regularHolidayCount * (this.getHourlyRate() * 8 || 0),
          }));
        }

        updatedObject = {
          ...employeePayroll,
          adjustments: employeePayroll?.adjustments || [
            { adjustmentAmount: "", adjustmentDescription: "" },
          ],
          subtotal: employeePayroll.subtotal || 0,
          total: employeePayroll.total || 0,
          moreOtherDeductions: employeePayroll?.moreOtherDeductions || [],
        };
      } else {
        updatedObject = {
          ...employeePayroll,
          adjustments: employeePayroll?.adjustments || [
            { adjustmentAmount: "", adjustmentDescription: "" },
          ],
          subtotal:
            employeePayroll.subtotal ||
            this.view.state.userInfo.monthlyRate / 2 ||
            0,
          moreOtherDeductions: employeePayroll?.moreOtherDeductions || [],
        };
      }

      this.view.setState((prev) => ({
        ...prev,
        employeeIncreasesAndDeductions: updatedObject,
        increases: employeePayroll.increases,
      }));
    } catch (error) {
      console.log(error);
    }
    this.progress = false;
  }

  deleteObject(obj) {
    delete obj["deductionLabel"];
    delete obj["deductionAmount"];
    delete obj["adjustmentDescription"];
    delete obj["adjustmentAmount"];
  }

  days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  onEmployeeIncreasesAndDeductionsChange(value, field, date) {
    let obj = {};

    obj[field] = !value || isNaN(value) ? 0 : value;
    obj = { ...this.view.state.employeeIncreasesAndDeductions, ...obj };
    this.deleteObject(obj);
    this.view.setEmployeeIncreasesAndDeductsObject(obj);
    if (this.view.state.object.type === "Weekly") {
      this.computeWeeklySubtotal(date, field);
    } else {
      this.computeTotals();
    }
  }

  async deductCashAdvance() {
    const values = this.view.state.employeeIncreasesAndDeductions;
    const { cashAdvanceInfo } = this.view.state;
    let { CADeductions } = values || 0;

    if (!cashAdvanceInfo) return;

    for (const item of cashAdvanceInfo) {
      let deduction = 0;
      const amount = parseFloat(item.amounts.replace(/,/g, ""));

      if (CADeductions > amount) {
        deduction = amount;
        CADeductions -= amount;
      } else {
        deduction = CADeductions;
        CADeductions = 0;
      }

      const newObj = {
        ...item,
        deduction: deduction?.toString(),
      };

      console.log("OBJ", newObj);

      await this.upsertUseCase.execute("cash_advance", newObj);
    }
  }

  async submit() {
    const object = this.view.state.employeeIncreasesAndDeductions;
    if (!object) return;
    const { payrollId, userId, payrollType } = this.view.getArgument();
    const weeklySubtotal =
      payrollType === "Weekly" ? this.getWeeklySubtotal() : null;

    // const totalTSDeductions = this.timeSheetDeductions().reduce(
    //   (total, deduction) => total + deduction,
    //   0
    // );
    const totalTSDeductions = this.getTotalTsDeductions();
    console.log("NGAYON NA", totalTSDeductions);

    console.log("NGAYON NA OBJECT", object);

    object.tsDeductions = totalTSDeductions;

    const newObject = {
      ...object,
      payrollId,
      userId,
      ...(payrollType === "Weekly"
        ? {
            increases: this.view.state.increases,
            deductions: this.getTotalDeductions(),
            tsDeductions: totalTSDeductions,
            daysWorked: this.getDaysWorked(),
            mondayTotal: weeklySubtotal?.mondayTotal,
            tuesdayTotal: weeklySubtotal?.tuesdayTotal,
            wednesdayTotal: weeklySubtotal?.wednesdayTotal,
            thursdayTotal: weeklySubtotal?.thursdayTotal,
            fridayTotal: weeklySubtotal?.fridayTotal,
            saturdayTotal: weeklySubtotal?.saturdayTotal,
            sundayTotal: weeklySubtotal?.sundayTotal,
            subtotal: weeklySubtotal?.total,
            total: weeklySubtotal?.total - this.getTotalDeductions(),
            totalRegularPay: weeklySubtotal?.totalRegularPay,
            totalNDPay: weeklySubtotal?.totalNDPay,
            totalOTPay: weeklySubtotal?.totalOTPay,
            caRequestAmount: this.getCARequest(),
          }
        : {}),
    };

    console.log("new nsed", newObject);

    try {
      this.view
        .showEditPayrollDialog()
        .then(async () => {
          await this.deductCashAdvance();
          await this.upsertUseCase.execute("employee_payroll_info", newObject);
          this.view.submissionSuccess();
          this.view.showToast("Payroll has been successfully saved.");
          this.view.navigateBack();
        })
        .catch((error) => console.log(error));
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default EditIndividualPayrollPresenter;
